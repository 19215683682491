import { useRef, useCallback } from "react";
import tracking from "frontend/tracking";

const MIN_CHARACTERS_TO_TRACK = 2;

export function useTrackUserTyping() {
  const eventAlreadyTriggered = useRef(false);

  return useCallback((value: string, eventName: string, eventProperties?: Record<string, string>) => {
    if (!eventAlreadyTriggered.current && value.length > MIN_CHARACTERS_TO_TRACK) {
      eventAlreadyTriggered.current = true;
      tracking.trackAnalyticsEvent(eventName, eventProperties);
    }
  }, []);
}
