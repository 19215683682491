import { PlanInfo, User } from "shared/datamodel/schemas/user";
import { isTrialActive } from "shared/util/billing";

export function isUserPaying(planInfo: PlanInfo | null) {
  if (!planInfo) return false;
  const trialActive = isTrialActive(planInfo);
  const isUserPaying = !planInfo?.is_free && !trialActive;
  return isUserPaying;
}

export function getAdminIds(users: Map<number, User>): number[] {
  const adminUserIds: number[] = [];
  users.forEach((user: User, userId: number) => {
    if (user.isAdmin) {
      adminUserIds.push(userId);
    }
  });
  return adminUserIds;
}

export function getDomain(email: string) {
  return email.slice(Math.max(0, email.indexOf("@") + 1));
}
