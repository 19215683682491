import { getIntegrationConfigData } from "frontend/api";
import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { useFeatureFlag } from "./use-feature-flag/use-feature-flag";

export type MondayBoard = {
  id: string;
  name: string;
  board_kind: string;
  columns: {
    id: string;
    title: string;
    type: string;
    settings_str: string;
  }[];
  groups: {
    id: string;
    title: string;
  }[];
  subitem_board?: MondayBoard;
};

const boardsAtom = atomFamily((_integrationId: string) => atom<MondayBoard[]>([]));

export default function useMondayBoardsPicker(
  integrationId: string,
  searchTerm: string = "",
  includeSubitems: boolean = false
) {
  const [allBoards, setAllBoards] = useAtom(boardsAtom(`${integrationId}${includeSubitems}`));
  const [boards, setBoards] = useState<MondayBoard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const shouldExcludeRestrictedColumns = useFeatureFlag("restricted-columns");

  const filteredBoards = useMemo(() => {
    if (!debouncedSearchTerm) {
      return allBoards;
    }
    if (boards.length > 0) {
      return boards;
    }
    return allBoards.filter((board) => board.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()));
  }, [debouncedSearchTerm, boards, allBoards]);

  useEffect(() => {
    if (allBoards.length) {
      return;
    }
    fetchBoards("", includeSubitems);
  }, [includeSubitems]);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      return;
    }
    fetchBoards(debouncedSearchTerm, includeSubitems);
  }, [debouncedSearchTerm, includeSubitems]);

  async function fetchBoards(searchTerm: string, includeSubitems: boolean) {
    setIsLoading(true);
    setFailedToLoad(false);
    setBoards([]);
    try {
      let { boards } = await getIntegrationConfigData(integrationId, [], {
        searchTerm,
        shouldExcludeRestrictedColumns,
      });
      if (includeSubitems) {
        const subitemBoardIds = boards.map((board: MondayBoard) => board.subitem_board?.id).filter((id?: string) => id);
        const { boards: subitemBoards } = await getIntegrationConfigData(integrationId, subitemBoardIds, {
          shouldExcludeRestrictedColumns,
        });
        const keyedSubitemBoards = subitemBoards.reduce((acc: Record<string, MondayBoard>, board: MondayBoard) => {
          acc[board.id] = board;
          return acc;
        }, {});
        boards = boards.reduce((acc: MondayBoard[], board: MondayBoard) => {
          if (board.subitem_board?.id) {
            board.subitem_board = keyedSubitemBoards[board.subitem_board.id];
          }
          if (board.subitem_board) {
            acc.push(board);
          }
          return acc;
        }, []);
      }
      if (searchTerm) {
        setBoards(boards);
      } else {
        setAllBoards(boards);
      }
    } catch (e) {
      setFailedToLoad(true);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  return { boards: filteredBoards, isLoading, failedToLoad };
}
